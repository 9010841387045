import { createStore } from "vuex";
import axios from "axios";
import $ from "jquery";
import { globalVars, globalProperties } from "@/main";

const store = createStore({
  state: {
    lang: [],
    language: "vn",
    //**nx
    //{{
    loading: false,
    casinoList: [],
    slotGameList: [],
    allSlotGameList: [],
    miniGameList: [],
    newCasinoList: [],
    newSlotGameList: [],
    hotCasinoList: [],
    hotSlotGameList: [],
    notificationPopup: false,
    maintenancePopup: false,
    homePopup: false,
    homePopupTab: "tab1",
    itemGamePopup: false,
    loggedIn: false,
    user: {},
    //}}
  },
  getters: {
    //**nx
    //{{
    langweb: (state) => (variable) => {
      state.lang.filter((e) => {
        if (e.variable === variable) {
          if (state.language === "en") {
            variable = e.en;
          } else if (state.language === "cn") {
            variable = e.cn;
          } else if (state.language === "jp") {
            variable = e.jp;
          } else {
            variable = e.vn;
          }
          if (process.env.VUE_APP_FIX_LANG) {
            variable = e.vn;
          }
        }
      });
      return variable;
    },
    //}}
  },
  mutations: {
    changLang(state) {
      axios.get(`https://api.spc789.com/api/language`).then((res) => {
        // state.lang = [...state.lang, res.data.data.language];
        state.lang = res.data.data.language;
      });
    },
    clickEn(state) {
      state.language = "en";
    },
    clickKr(state) {
      state.language = "vn";
    },
    clickCn(state) {
      state.language = "cn";
    },
    clickJp(state) {
      state.language = "jp";
    },
    //**nx
    //{{
    setLoading(state, payload) {
      state.loading = payload;
    },
    setCasinoList(state, payload) {
      state.casinoList = payload;
    },
    setSlotGameList(state, payload) {
      state.slotGameList = payload;
    },
    setAllSlotGameList(state, payload) {
      state.allSlotGameList = payload;
    },
    setMiniGameList(state, payload) {
      state.miniGameList = payload;
    },
    setCasinoListNew(state, payload) {
      state.newCasinoList = payload;
    },
    setCasinoListHot(state, payload) {
      state.hotCasinoList = payload;
    },
    setSlotGameListNew(state, payload) {
      state.newSlotGameList = payload;
    },
    setSlotGameListHot(state, payload) {
      state.hotSlotGameList = payload;
    },
    showNotificationPopup(state, payload) {
      state.notificationPopup = payload;
    },
    showMaintenancePopup(state, payload) {
      state.maintenancePopup = payload;
    },
    showHomePopup(state, { open, tab }) {
      state.homePopup = open;
      state.homePopupTab = typeof tab === "string" ? tab : "tab" + tab;
    },
    showItemGamePopup(state, { open }) {
      state.itemGamePopup = open;
    },
    setUser(state, payload) {
      if (payload) {
        const amount = Number(payload.amount);
        const casino = Number(payload.casino);
        state.user = {
          ...payload,
          amount,
          casino,
          totalAmount: amount + casino,
        };
        state.loggedIn = !!payload.name;
      } else {
        state.user = {};
        state.loggedIn = false;
      }
    },
    //}}
  },
  actions: {
    changLang(context) {
      context.commit("changLang");
    },
    //**nx
    //{{
    fetchGameList(context) {
      axios.get(globalVars.api_gamecasino).then((response) => {
        const list1 = [];
        const list2 = [];
        const list3 = [];
        for (const d of response.data.data) {
          if (!d.image) {
            continue;
          }
          if (d.type === "casino") {
            list1.push(d);
          }
          if (d.type === "slot") {
            list2.push(d);
          }
          if (d.type === "mini") {
            list3.push(d);
          }
        }
        context.commit("setCasinoList", list1);
        context.commit("setSlotGameList", list2);
        context.commit("setMiniGameList", list3);
      });
    },
    fetchNewGameList(context) {
      axios.get(globalVars.api_gamecasinoNew).then((response) => {
        const casino = [];
        const slot = [];
        for (const d of response.data.data) {
          if (d.bType === "casino") {
            casino.push(d);
          }
          if (d.bType === "slot") {
            slot.push(d);
          }
        }
        context.commit("setCasinoListNew", casino);
        context.commit("setSlotGameListNew", slot);
      });
    },
    fetchHotGameList(context) {
      axios.get(globalVars.api_gamecasinoHot).then((response) => {
        const casino = [];
        const slot = [];
        for (const d of response.data.data) {
          if (d.bType === "casino") {
            casino.push(d);
          }
          if (d.bType === "slot") {
            slot.push(d);
          }
        }
        context.commit("setCasinoListHot", casino);
        context.commit("setSlotGameListHot", slot);
      });
    },
    showNotificationPopup(context, open) {
      context.commit("showNotificationPopup", open);
    },
    showMaintenancePopup(context, open) {
      context.commit("showMaintenancePopup", open);
    },
    showHomePopup(context, { open, tab }) {
      const token = window.localStorage.getItem("token");
      if (token) {
        context.commit("showHomePopup", { open, tab });
        $("body").css("overflow", "hidden");
      } else {
        context.commit("showNotificationPopup", true);
      }
    },
    setUser(context, payload) {
      context.commit("setUser", payload);
    },
    clearUser(context) {
      context.commit("setUser", null);
    },
    async getGameProcess(context, { api, code, type }) {
      if (context.state.loading) {
        return;
      }
      console.log("getGameProcess", { api, code, type });
      context.commit("setLoading", true);
      $(".loading_show").show().fadeIn(10);
      const token_user = window.localStorage.getItem("token");
      const response = await axios.post(
        globalVars.api_liveprocess,
        {
          gamecode: code,
          gametype: type,
          gameapi: api,
        },
        {
          headers: {
            Authorization: "Bearer " + token_user,
          },
        }
      );
      if (response.data.data.result === 1) {
        var size = {
          width: window.innerWidth || document.body.clientWidth,
          height: window.innerHeight || document.body.clientHeight,
        };
        //창 크기 지정
        var width = size.width;
        var height = size.height;
        //pc화면기준 가운데 정렬
        var left = window.screen.width / 2 - width / 2;
        var top = window.screen.height / 4;
        //윈도우 속성 지정
        var windowStatus =
          "width=" +
          width +
          ", height=" +
          height +
          ", left=" +
          left +
          ", top=" +
          top +
          ", scrollbars=yes, status=yes, resizable=yes, titlebar=yes";
        //연결하고싶은url
        const url = response.data.data.url;
        //등록된 url 및 window 속성 기준으로 팝업창을 연다.
        window.open(url, "gamepopup", windowStatus);
      } else {
        globalProperties.$swal({
          position: "top-end",
          icon: "error",
          title: response.data.data.url,
          showConfirmButton: false,
          timer: 3000,
        });
      }
      context.commit("setLoading", false);
      $("#loading_show").css("display", "none");
    },
    async showItemGamePopup(context, { open, title, code }) {
      context.commit("showItemGamePopup", { open });
      //**debug
      // console.log("showItemGamePopup.1", title, code);
      if (title && code) {
        const token = window.localStorage.getItem("token");
        if (!token) {
          return context.commit("showNotificationPopup", true);
        }

        $(".loading_show").show().fadeIn(10);

        const response = await axios.get(globalVars.api_listgameSlot + code, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        //**debug
        // console.log("showItemGamePopup.2", response.data.data);
        context.commit("setAllSlotGameList", response.data.data);

        $(".namegaming").text(title);
        $("#loading_show").css("display", "none");
      }
    },
    //}}
  },
});

export default store;
