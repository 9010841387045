<script setup>
import { defineProps, computed, onBeforeMount, onUnmounted, ref } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  id: String,
  title: String,
  listName: String,
  type: Number,
});

const store = useStore();
const list = computed(() => store.state[props.listName]);

const onClickItem = (item) => {
  if (!store.state.loggedIn) {
    store.dispatch("showNotificationPopup", true);
  } else if (item.maintenance === 1) {
    store.dispatch("showMaintenancePopup", true);
  } else {
    if (props.listName.startsWith("slot")) {
      store.dispatch("showItemGamePopup", { open: true, ...item });
    } else {
      store.dispatch("getGameProcess", {
        api: item.vender,
        code: item.code,
        type: props.type,
      });
    }
  }
};

const MIN_LIST = 6;
const maxList = ref(MIN_LIST);
const isSmallScreen = ref(false);

const onClickMore = () => {
  if (maxList.value === MIN_LIST) {
    maxList.value = list.value.length;
  } else {
    maxList.value = MIN_LIST;
  }
};

function handleMediaQueryChange(event) {
  isSmallScreen.value = event.matches;
}

onBeforeMount(() => {
  const mediaQuery = window.matchMedia("(max-width: 768px)");
  mediaQuery.addEventListener("change", handleMediaQueryChange);
  handleMediaQueryChange(mediaQuery);
});

onUnmounted(() => {
  const mediaQuery = window.matchMedia("(max-width: 768px)");
  mediaQuery.removeEventListener("change", handleMediaQueryChange);
});
</script>

<template>
  <div class="pt-2">
    {{ title }}
    <button v-if="!isSmallScreen" class="underline" @click="onClickMore">
      全て表示
    </button>
  </div>
  <div
    :id="id"
    :class="
      [
        'tab1 grid grid-cols-6 py-4',
        'sm:grid-cols-0 sm:grid-flow-col sm:overflow-x-auto gap-4',
      ].join(' ')
    "
  >
    <a
      href="javascript:void(0)"
      v-for="(item, index) in isSmallScreen ? list : list.slice(0, maxList)"
      :key="index"
      class="lqd-fb lqd-fb-style-1 relative overflow-hidden lqd-fb-zoom-img-onhover h-pt-100 rounded-6 mb-0"
      data-inview="true"
      data-slideelement-onhover="true"
      data-slideelement-options='{"visibleElement": "h6, h2", "hiddenElement": ".lqd-fb-txt"}'
      @click="onClickItem(item)"
    >
      <div class="lqd-fb-inner lqd-overlay flex">
        <div
          class="lqd-fb-img lqd-overlay flex overflow-hidden scale-1 opacity-100"
        >
          <figure class="w-full" data-responsive-bg="true">
            <img :src="item.image" :alt="item.title" />
          </figure>
          <a
            href="javascript:void(0);"
            title="Services"
            class="liquid-overlay-link inline-block w-full h-full absolute top-0 left-0 text-start overflow-hidden indent-hide"
          ></a>
        </div>
        <div class="lqd-fb-content lqd-overlay flex items-end">
          <div class="lqd-fb-bg lqd-overlay flex"></div>
          <div
            class="lqd-fb-content-inner relative p-2rem w-full"
            data-custom-animations="true"
            data-ca-options='{"triggerHandler": "inview", "animationTarget": "all-childs", "duration" : 1200, "delay" : 120,  "startDelay" : 250, "initValues": { "y" : 30, "opacity" : 0 }, "animations": { "y" : 0, "opacity" : 1 } }'
          >
            <h2
              class="my-0 text-22 font-medium"
              data-split-text="true"
              data-split-options='{"type": "lines"}'
            >
              <a href="javascript:void(0);">{{ item.title }}</a>
            </h2>
            <div class="lqd-fb-txt mt-2rem">
              <div
                class="cursor-pointer"
                @click="onClickItem(item)"
                :class="
                  [
                    'btn btn-sm btn-play btn-hover-txt-liquid-x btn-icon-left text-primary bg-white hover:bg-accent hover:text-white',
                  ].join(' ')
                "
              >
                <div
                  class="flex flex-row font-weight-bold mt-1 mx-1"
                  style="color: black"
                >
                  <span
                    class="btn-txt text-start"
                    data-text="Play Game"
                    data-split-text="true"
                    data-split-options='{"type": "chars, words"}'
                  >
                    <span>ゲームで遊ぶ!</span>
                  </span>
                  <span class="btn-icon"
                    ><i class="flaticon flaticon-poker-cards-2"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<style scoped></style>
