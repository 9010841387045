<template>
  <header
    id="site-header"
    class="main-header"
    data-sticky-header="true"
    data-sticky-values-measured="false"
    data-sticky-options='{"smartSticky":true}'
  >
    <HeaderProfile />
    <HeaderNav />
  </header>
  <!-- Popup container start -->
  <div
    class="lity lity-inline lity-closed"
    role="dialog"
    aria-label="Dialog Window (Press escape to close)"
    tabindex="-1"
    data-modal-type="compact"
  >
    <div class="lity-backdrop"></div>
    <div class="lity-wrap" data-lity-close role="document">
      <div class="lity-loader" aria-hidden="true">Loading...</div>
      <div class="lity-container relative">
        <div class="lity-content"></div>
        <button
          class="lity-close"
          type="button"
          aria-label="Close (Press escape to close)"
          data-lity-close=""
        >
          ×
        </button>
      </div>
    </div>
  </div>
  <!-- Popup container end -->
  <LoginPopup />
  <RegisterPopup />
  <NotificationPopup />
  <MaintenancePopup />
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

//**??
// import Cashin_city from "@/components/Cashin";
// // import ListCashin_city from "@/components/ListCashIn";
// import Cashout_city from "@/components/Cashout";
// import ListCashout_city from "@/components/ListCashOut";
// import Notification_city from "@/components/Notification";
// import Chat_city from "@/components/ChatList";
// import Contact_city from "@/components/SendChat";
// import ChangeInfo_city from "@/components/ChangeInfo";
// import PointToAmount_city from "@/components/PointToAmount";
// import Betting_city from "@/components/Betting";
// import Gift_city from "@/components/Gift";
// import Send_city from "@/components/Send";
// import FAQ from "@/components/FAQ";
//**nx
//{{
import HeaderProfile from "@/components/header/HeaderProfile.vue";
import HeaderNav from "@/components/header/HeaderNav.vue";
import LoginPopup from "@/components/popup/LoginPopup.vue";
import RegisterPopup from "@/components/popup/RegisterPopup.vue";
import NotificationPopup from "@/components/popup/NotificationPopup.vue";
import MaintenancePopup from "@/components/popup/MaintenancePopup.vue";
//}}

import { useStore } from "vuex";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "Header_city",
  setup() {
    const store = useStore();
    store.dispatch("changLang");
    store.dispatch("fetchGameList");
    store.dispatch("fetchNewGameList");
    store.dispatch("fetchHotGameList");
    return {
      clickEn() {
        store.commit("clickEn");
      },
      clickKr() {
        store.commit("clickKr");
      },
      clickCn() {
        store.commit("clickCn");
      },
      clickJp() {
        store.commit("clickJp");
      },
    };
  },
  data() {
    return {
      notifi: "",
      popup: "",
      chat: "",
      send: "",
      listpopup: [],
      readMore: [false],
      showpp: "",
      cssnone: "displaynone",
      tabSelected: "tab1",
      set: 0,
      listSendPopup: [],
      id_lg: "",
      level: window.localStorage.getItem("level"),
      offline: window.localStorage.getItem("offline"),
      listSend_header: [],
      audioSend: 0,

      rightPaneActive: false,
      currentDateTime: "",
      loginModalOpen: false,
      selectedLang: "kr",
      toggleLangSelect: false,
    };
  },
  mounted() {
    this.updateDateTime();
    this.intervalId = setInterval(() => {
      this.updateDateTime();
    }, 1000);
  },
  created() {
    const token = window.localStorage.getItem("token");
    if (token !== null) {
      this.getListInfo();
      this.getNumberinfo();
      this.getListPopup();
      this.resettoken();
      setInterval(this.getListInfo, 5000);
      setInterval(this.getNumberinfo, 5000);
      this.getListSendpp();
      this.getListSend();
    }
  },
  components: {
    HeaderProfile,
    HeaderNav,
    LoginPopup,
    RegisterPopup,
    MaintenancePopup,
    NotificationPopup,
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  computed: {
    ...mapState(["tabSelectedd"]),
  },
  methods: {
    changeLang(lang) {
      this.toggleLangSelect = false;
      this.selectedLang = lang;
      if (lang === "kr") {
        this.clickKr();
      } else if (lang === "en") {
        this.clickEn();
      } else if (lang === "cn") {
        this.clickCn();
      } else if (lang === "jp") {
        this.clickJp();
      }
    },
    updateDateTime() {
      const now = new Date();
      this.currentDateTime = moment(now).format("YYYY-MM-DD HH:mm:ss");
    },
    getListInfo() {
      this.axios
        .get(this.api_infouser, {
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((response) => {
          const user = response.data.data.user;
          this.$store.dispatch("setUser", user);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            this.$swal({
              position: "top-end",
              icon: "error",
              title: e.response.data.message,
              showConfirmButton: false,
              timer: 11000,
            });
            setTimeout(
              function () {
                this.axios.post(this.api_logout, {}).then((res) => {
                  if (res.data.success === true) {
                    localStorage.clear();
                  }
                });
              }.bind(this),
              10000
            );
            setTimeout(function () {
              window.location.href = "/";
            }, 13000);
          }
        });
    },
    resettoken() {
      if (window.localStorage.getItem("token") !== null) {
        let timetk = window.localStorage.getItem("time_token");
        let today = new Date();
        let date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        let time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        let timestamp = date + " " + time;
        let timereset =
          Math.round(+new Date(timestamp) / 1000) -
          Math.round(+new Date(timetk) / 1000);
        let result = timereset / 60;
        if (result > 60) {
          this.axios.post(this.api_logout).then((res) => {
            if (res.data.success === true) {
              localStorage.clear();
              window.location.href = "/";
              this.store.dispatch("clearUser");
            }
          });
          //**??
          // this.axios.get(this.api_resettoken, {
          //     headers: {
          //         Authorization: 'Bearer ' + window.localStorage.getItem('token')
          //     }
          // }).then((response) => {
          //     window.localStorage.setItem('token', response.data.data)
          //     let today = new Date();
          //     let date = today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate();
          //     let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
          //     let timestamp = date + ' ' + time;
          //     window.localStorage.setItem('time_token', timestamp)
          // }).catch(e => {
          //     if (e?.response?.status === 500) {
          //         alert('세션이 종료되었습니다. 계속하려면 로그인하세요.')
          //         localStorage.clear();
          //         this.store.dispatch("clearUser");
          //     }
          // })
        }
      }
    },
    getNumberinfo() {
      this.axios
        .get(this.api_number, {
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.notifi = response.data.data.notifi;
          this.popup = response.data.data.popup;
          this.chat = response.data.data.chat;
          this.send = response.data.data.send;
        });
    },
    getListPopup() {
      var token_user = window.localStorage.getItem("token");
      this.axios
        .get(this.api_listpopup, {
          headers: {
            Authorization: "Bearer " + token_user,
          },
        })
        .then((response) => {
          response.data.data.list_data.forEach((item) => {
            if (this.$cookies.get(item.id) !== "close") {
              this.listpopup.push(item);
            }
            if (this.listpopup.length > 0) {
              this.cssnone = "";
            }
          });
        });
    },
    getListSendpp() {
      this.axios
        .get(this.api_listSend, {
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.listSendPopup = response.data.data.list_data;
          this.id_lg = response.data.data.user_id;
          // this.listSendPopup.forEach((value) => {
          //     let checkSend = Object.values(value.user_view.split(',').map((e) => parseInt(e))).includes(this.id_lg)
          //     if(!checkSend){
          //         this.tabSelected = 'tab11'
          //         $('.popupMain').show();
          //         $('body').css('overflow', 'hidden')
          //         return;
          //     }
          // });
          const array = this.listSendPopup;
          let shouldExit = false;

          array.forEach((value) => {
            if (shouldExit) {
              return; // Exit the loop
            }
            let checkSend = Object.values(
              value.user_view.split(",").map((e) => parseInt(e))
            ).includes(this.id_lg);
            if (!checkSend) {
              //**??
              // $(".popupMain").show();
              // $("body").css("overflow", "hidden");
              // this.tabSelected = "tab11";
              // shouldExit = true; // Set the flag to exit
              return;
            }
          });
        });
    },
    getListSend() {
      this.axios
        .get(this.api_listSend, {
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.listSend_header = response.data.data.list_data;
          this.id_lg = response.data.data.user_id;
          const array = this.listSend_header;
          let shouldExit = false;

          array.forEach((value) => {
            if (shouldExit) {
              return; // Exit the loop
            }
            let checkSend = Object.values(
              value.user_view.split(",").map((e) => parseInt(e))
            ).includes(this.id_lg);
            // console.log(checkSend);
            if (!checkSend) {
              this.audioSend = 1;
              shouldExit = true; // Set the flag to exit
              return;
            } else {
              this.audioSend = 0;
            }
          });
        });
    },
    change_amount_header() {
      this.axios
        .get(this.api_infouser, {
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("token"),
          },
        })
        .then((response) => {
          const user = response.data.data.user;
          this.$store.dispatch("setUser", user);
        });
    },
  },
};
</script>
<style></style>
