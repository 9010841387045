<template>
  <HomePopup />
  <ItemGamePopup />
  <div class="wraper-root">
    <GameBannersSmallPc />
    <GameBannersSmallMobile />
    <main class="page-content w-ba" id="games-section">
      <section class="toggle-section">
        <div class="tab-content-toogle game-list">
          <section class="game-provider">
            <div class="container">
              <GameList
                id="new-casino-section"
                title="新作ゲーム"
                list-name="newCasinoList"
                :type="1"
              />
              <GameList
                id="hot-casino-section"
                title="人気ゲーム"
                list-name="hotCasinoList"
                :type="1"
              />
              <GameList
                id="casino-section"
                title="ライブカジノ"
                list-name="casinoList"
                :type="1"
              />
              <GameList
                id="new-slotgame-section"
                title="新作ゲーム"
                list-name="newSlotGameList"
                :type="2"
              />
              <GameList
                id="hot-slotgame-section"
                title="人気ゲーム"
                list-name="hotSlotGameList"
                :type="2"
              />
              <GameList
                id="slotgame-section"
                title="スロット"
                list-name="slotGameList"
                :type="2"
              />
            </div>
          </section>
        </div>
      </section>
      <section class="contact-section">
        <div class="container">
          <div>
            <!-- <img src="../assets/img/icons/telegram.svg" alt=""> -->
          </div>
        </div>
      </section>
      <input type="hidden" name="datacode" class="datacode" value="0" />
      <!--            <div-->
      <!--                id="loading_show"-->
      <!--                class="loading_show"-->
      <!--                style="-->
      <!--                    position: fixed;-->
      <!--                    left: 0;-->
      <!--                    bottom: 0;-->
      <!--                    top: 0%;-->
      <!--                    right: 0%;-->
      <!--                    text-align: center;-->
      <!--                    background: rgba(0, 0, 0, 0.5);-->
      <!--                    z-index: 999999999;-->
      <!--                "-->
      <!--            >-->
      <!--                <div class="windows8">-->
      <!--                    <img src="../assets/img/icons/loader.svg" alt="" />-->
      <!--                </div>-->
      <!--            </div>-->
      <section class="board-section w-ba">
        <div class="container max-width-gib dflex-ac-jc">
          <NoticeBoard />
          <FaqBoard />
          <CashInOut />
          <ItemGamePopup />
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import $ from "jquery";
//**nx
//{{

import GameBannersSmallPc from "@/components/home/GameBannersSmallPc.vue";
import GameBannersSmallMobile from "@/components/home/GameBannersSmallMobile.vue";
import CashInOut from "@/components/home/CashInOut.vue";
import HomePopup from "@/components/popup/HomePopup.vue";
import ItemGamePopup from "@/components/popup/ItemGamePopup.vue";
import NoticeBoard from "@/components/board/NoticeBoard.vue";
import FaqBoard from "@/components/board/FaqBoard.vue";
import GameList from "@/components/home/GameList.vue";
//}}

$(document).ready(function () {
  $(document).on("click", ".notifyhome_click", function () {
    $(this).parent().find(".substring_content_tbs").show();
    $(".toggle-section").css("z-index", "-1");
    $(".footer-section").css("z-index", "-1");
    // $("body").css("overflow", "hidden");
  });
  $(document).on("click", ".close_notis", function () {
    $(this).parents(".substring_content_tbs").hide();
    $(".toggle-section").css("z-index", "0");
    $(".footer-section").css("z-index", "0");
    $("body").css("overflow", "initial");
  });
  $(document).on("click", ".notifyhome_clicks", function () {
    $(this).parent().find(".substring_content_tbss").show();
    $(".toggle-section").css("z-index", "-1");
    $(".footer-section").css("z-index", "-1");
    // $("body").css("overflow", "hidden");
  });
  $(document).on("click", ".close_notiss", function () {
    $(this).parents(".substring_content_tbss").hide();
    $(".toggle-section").css("z-index", "0");
    $(".footer-section").css("z-index", "0");
    // $("body").css("overflow", "initial");
  });
  $(document).on("click", ".more-btn", function () {
    $(".subpage-content .subpagecontent-header").text(
      `${$(this).siblings().text()}`
    );
  });
});

export default {
  name: "Home_city",
  components: {
    GameList,
    GameBannersSmallPc,
    GameBannersSmallMobile,
    CashInOut,
    HomePopup,
    ItemGamePopup,
    NoticeBoard,
    FaqBoard,
  },
};
</script>
<style scoped></style>
